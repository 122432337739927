@font-face {
  font-family: "Texta Alt";
  src: url("TextaAlt-Medium.eot");
  src: url("TextaAlt-Medium.eot?#iefix") format("embedded-opentype"),
    url("TextaAlt-Medium.woff2") format("woff2"), url("TextaAlt-Medium.woff") format("woff"),
    url("TextaAlt-Medium.ttf") format("truetype"),
    url("TextaAlt-Medium.svg#TextaAlt-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Texta Alt";
  src: url("TextaAlt-Heavy.eot");
  src: url("TextaAlt-Heavy.eot?#iefix") format("embedded-opentype"),
    url("TextaAlt-Heavy.woff2") format("woff2"), url("TextaAlt-Heavy.woff") format("woff"),
    url("TextaAlt-Heavy.ttf") format("truetype"),
    url("TextaAlt-Heavy.svg#TextaAlt-Heavy") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Texta Alt";
  src: url("TextaAlt-Bold.eot");
  src: url("TextaAlt-Bold.eot?#iefix") format("embedded-opentype"),
    url("TextaAlt-Bold.woff2") format("woff2"), url("TextaAlt-Bold.woff") format("woff"),
    url("TextaAlt-Bold.ttf") format("truetype"),
    url("TextaAlt-Bold.svg#TextaAlt-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
