@import url("https://rsms.me/inter/inter.css");
@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400;700&display=swap");

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden],
template {
  display: none;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  font-family: "Inter", sans-serif;
  font-feature-settings: "dlig", "zero", "ss01", "cv05", "cv10";
  -webkit-font-smoothing: antialiased;
  margin: 0;
}

a {
  background: transparent;
}
a:focus {
  outline: 0;
}
a:active,
a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

cite {
  font-style: normal;
  display: inline-block;
}

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}

mark {
  background: #ff0;
  color: #000;
}

code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 1em;
}

pre {
  white-space: pre-wrap;
}

q {
  quotes: "“" "”" "‘" "’";
}

small {
  font-size: 80%;
}

sub {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 0;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
  outline: 0;
}

legend {
  border: 0;
  padding: 0;
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
}

button,
input {
  line-height: normal;
}

button,
select {
  text-transform: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 0px;
  border: 0;
  outline: 0;
}

button,
html input[type="button"] {
  -webkit-appearance: button;
  cursor: pointer;
  border: 0;
  outline: 0;
  background: transparent;
}

input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
  border: 0;
  outline: 0;
  background: transparent;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

input {
  border: 0;
}
input[type="checkbox"],
input[type="radio"] {
  padding: 0;
}
input:focus {
  outline: 0;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
  border: 0;
  outline: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

ul {
  margin: 0;
  border: 0;
  padding: 0;
  list-style: none;
}
ul li {
  border: 0;
  padding: 0;
  list-style: none;
}

ol {
  margin: 0;
  border: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1em;
  line-height: 1.25;
  font-weight: normal;
  margin: 0;
  text-rendering: optimizeLegibility;
  word-wrap: break-word;
}

p {
  word-wrap: break-word;
}

body,
html {
  height: 100%;
  min-height: 100%;
}

a {
  text-decoration: none;
}

input,
label,
textarea,
table {
  font-size: 14px;
  line-height: 22px;
}
